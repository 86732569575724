import { render, staticRenderFns } from "./IDJourney.vue?vue&type=template&id=4fa5bf27&scoped=true&"
import script from "./IDJourney.vue?vue&type=script&lang=js&"
export * from "./IDJourney.vue?vue&type=script&lang=js&"
import style0 from "./IDJourney.vue?vue&type=style&index=0&id=4fa5bf27&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa5bf27",
  null
  
)

export default component.exports